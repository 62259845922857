import React from 'react';
import { Component } from 'react';
import classNames from 'classnames';

import { cropImageFromSource, FacingMode, getUserMediaDevice } from '../../services/Image';

import styles from './camera.module.scss';

interface Props {
  aspectRatio: [number, number];
  compression?: number;
  facingMode?: FacingMode;
  className?: string;

  onPhotoTaken(dataUri: string): unknown;

  onError?(error: string): unknown;
}

interface State {
  loaded: boolean;
  error: string;
  facingMode: FacingMode;
}

export default class Camera extends Component<Props, State> {
  static defaultProps = {
    facingMode: FacingMode.ENVIRONMENT,
  };

  public state = {
    loaded: false,
    error: null,
    facingMode: this.props.facingMode,
  };

  private container = React.createRef<HTMLDivElement>();
  private video = React.createRef<HTMLVideoElement>();
  private stream: MediaStream;

  private get aspectRatio(): number {
    return this.props.aspectRatio[0] / this.props.aspectRatio[1];
  }

  private async load() {
    try {
      this.stream = await getUserMediaDevice({
        audio: false,
        video: {
          width: { ideal: this.props.aspectRatio[0] * 100 },
          height: { ideal: this.props.aspectRatio[1] * 100 },
          facingMode: this.props.facingMode,
          aspectRatio: this.aspectRatio,
          // @ts-expect-error TS2353: Object literal may only specify known properties, and resizeMode does not exist in type MediaTrackConstraints
          resizeMode: 'none',
        },
      });
      this.video.current.srcObject = this.stream;
      this.video.current.play();
      this.setState({ loaded: true });
    } catch (error) {
      this.props.onError?.(error);
      this.setState({ error: error.message });
    }
  }

  private unload() {
    if (!this.stream) return;
    this.stream.getTracks().forEach(track => {
      track.stop();
    });
    this.video.current.src = '';
    this.video.current.srcObject = null;
    this.stream = null;
  }

  private handleClick = async () => {
    if (this.state.error || !this.state.loaded) return;
    try {
      this.video.current.pause();
      const croppedImage = await cropImageFromSource(this.video.current, this.aspectRatio, this.props.compression);
      this.props.onPhotoTaken(croppedImage);
    } catch (error) {
      this.props.onError?.(error);
      this.setState({ error: error.message });
    }
  };

  private handleFlip = () => {
    this.setState(prev => ({
      loaded: false,
      facingMode: prev.facingMode === FacingMode.ENVIRONMENT ? FacingMode.USER : FacingMode.ENVIRONMENT,
    }));
    this.unload();
    this.load();
  };

  public componentDidMount() {
    this.load();
  }

  public componentWillUnmount() {
    this.unload();
  }

  public componentDidUpdate(prevProps: Props) {
    if (
      this.video.current &&
      this.stream &&
      (this.props.facingMode !== prevProps.facingMode || this.props.aspectRatio !== prevProps.aspectRatio)
    ) {
      this.unload();
      this.load();
    }
  }

  public render() {
    return (
      <div
        className={classNames(styles.camera, this.props.className, {
          [styles.loading]: !this.state.loaded,
        })}
      >
        <div className={`aspect-ratio-${this.props.aspectRatio[0]}-${this.props.aspectRatio[1]}`}>
          <div className={classNames('aspect-ratio-inside', styles.container)} ref={this.container}>
            {!this.state.error ? (
              <video autoPlay className={styles.video} muted playsInline ref={this.video} />
            ) : (
              <span className={styles.error}>{this.state.error}</span>
            )}
          </div>
        </div>

        <div className={styles.buttons}>
          <button className={styles.button} onClick={this.handleClick} type="button" />
          <button className={styles.flip} onClick={this.handleFlip} type="button">
            <i className="icon icon-sync" />
          </button>
        </div>
      </div>
    );
  }
}
