import React from 'react';
import { useCallback, useState } from 'react';
import Dropzone from 'react-dropzone';

import { FacingMode } from '../../services/Image';
import Loader from '../Loader';
import Modal from '../Modal';
import T from '../Translate';

import Camera from './Camera';

export interface ImageUploadAttributes {
  accept?: string | string[];
  imageCompression?: number;
  width?: number;
  height?: number;
  showCameraMask?: boolean;
  containerProps?: React.HTMLAttributes<HTMLDivElement> & {
    [k: string]: string;
  };
  crop?: boolean;
  dropDisabled?: boolean;
  switchFacingDisabled?: boolean;
  facingMode?: FacingMode;
  cameraHeader?: React.ReactNode;
  onClick?: () => void;
  children?: (image: string) => React.ReactNode;
}

interface Props extends ImageUploadAttributes {
  image?: string;
  onUpload(image: string): void | Promise<void>;
}

export default function ImageUpload({ onUpload, onClick, ...props }: Props) {
  const [state, setState] = useState({
    image: props.image,
    loadingDrop: false,
    loadingCamera: false,
    showCamera: false,
  });

  const onDrop = useCallback(
    files => {
      setState({
        image: null,
        loadingDrop: true,
        loadingCamera: false,
        showCamera: false,
      });
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      onClick && onClick();
      const reader = new FileReader();
      reader.onload = async event => {
        const image = event.target.result as string;
        setState({
          image,
          loadingDrop: true,
          loadingCamera: false,
          showCamera: false,
        });
        await onUpload(image);
        setState(prev => ({
          image: prev.image,
          loadingDrop: false,
          loadingCamera: false,
          showCamera: false,
        }));
      };
      reader.readAsDataURL(files[0]);
    },
    [onUpload, onClick],
  );

  const onTakePhoto = useCallback(
    async image => {
      setState({
        image,
        loadingDrop: false,
        loadingCamera: true,
        showCamera: false,
      });
      await onUpload(image);
      setState(prev => ({
        image: prev.image,
        loadingDrop: false,
        loadingCamera: false,
        showCamera: false,
      }));
    },
    [onUpload],
  );

  const handleCloseCamera = () => {
    setState(prev => ({
      image: prev.image,
      loadingDrop: false,
      loadingCamera: false,
      showCamera: false,
    }));
  };

  const style: React.CSSProperties = {};
  if (state.image && !props.children) {
    style.backgroundImage = `url(${state.image})`;
  }

  return (
    <>
      {props.children ? props.children(state.image) : null}

      <div {...props.containerProps} style={style}>
        {!props.dropDisabled && (
          <Dropzone accept={props.accept ?? ['image/jpeg', 'image/png']} multiple={false} onDrop={onDrop}>
            {({ getRootProps, getInputProps }) => {
              return (
                <div {...getRootProps()}>
                  <input {...getInputProps()} />
                  <button className="btn btn-sm btn-white" type="button">
                    {state.loadingDrop ? <Loader dotClassName="bg-primary" /> : <T id="global.upload" />}
                  </button>
                </div>
              );
            }}
          </Dropzone>
        )}
        <button
          className="btn btn-sm btn-white ml-2"
          onClick={() => {
            // eslint-disable-next-line @typescript-eslint/no-unused-expressions
            onClick && onClick();
            setState({
              image: null,
              loadingDrop: false,
              loadingCamera: false,
              showCamera: true,
            });
          }}
          type="button"
        >
          {state.loadingCamera ? <Loader dotClassName="bg-primary" /> : <T id="global.takePhoto" />}
        </button>
      </div>

      <Modal closeModal={handleCloseCamera} isOpen={state.showCamera} size="lg">
        {props.cameraHeader}
        <div className="position-relative">
          {/*<Camera
            idealResolution={{ width: props.width ?? 1600, height: props.height ?? 1200 }}
            onTakePhoto={onTakePhoto}
            imageType={IMAGE_TYPES.JPG}
            imageCompression={props.imageCompression ?? 0.8}
            idealFacingMode={facingMode}
            isImageMirror={facingMode === FacingMode.USER}
          />*/}
          <Camera
            aspectRatio={[props.width ?? 1600, props.height ?? 1200]}
            compression={props.imageCompression ?? 0.8}
            facingMode={props.facingMode ?? FacingMode.USER}
            onPhotoTaken={onTakePhoto}
          />
          {props.showCameraMask && <div className="react-html5-camera-photo__document-mask" />}
        </div>
      </Modal>
    </>
  );
}
